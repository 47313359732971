// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-1-js": () => import("./../../../src/pages/contact1.js" /* webpackChunkName: "component---src-pages-contact-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-linux-r-d-p-js": () => import("./../../../src/pages/linux-r-d-p.js" /* webpackChunkName: "component---src-pages-linux-r-d-p-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-v-p-n-js": () => import("./../../../src/pages/v-p-n.js" /* webpackChunkName: "component---src-pages-v-p-n-js" */),
  "component---src-pages-windows-r-d-p-js": () => import("./../../../src/pages/windows-r-d-p.js" /* webpackChunkName: "component---src-pages-windows-r-d-p-js" */)
}

